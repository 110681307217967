import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 500.000000 500.000000" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)" >


<path d="M3412 3169 c-149 -22 -255 -90 -309 -198 -28 -55 -33 -75 -33 -136 0
-57 5 -82 27 -125 39 -79 132 -165 259 -239 175 -103 242 -149 249 -170 9 -30
-11 -58 -57 -82 -70 -36 -210 -18 -358 45 -12 5 -27 -21 -66 -111 l-51 -118
61 -28 c130 -61 356 -84 495 -52 79 19 174 68 218 114 79 81 110 203 78 307
-27 86 -140 195 -295 286 -233 137 -230 134 -230 168 0 36 27 65 72 78 42 11
155 -1 235 -26 l62 -20 30 72 c16 39 37 88 46 109 l16 38 -63 29 c-117 55
-264 77 -386 59z"/>
<path d="M1150 2555 l0 -595 160 0 160 0 0 288 c1 256 2 285 15 267 9 -10 44
-53 79 -95 36 -41 73 -85 83 -97 17 -22 21 -22 83 -12 l65 11 75 105 75 104 3
-286 2 -285 160 0 160 0 0 595 0 595 -107 0 -108 -1 -80 -111 c-73 -101 -159
-220 -222 -306 l-24 -33 -105 133 c-57 73 -138 175 -180 226 l-75 92 -109 0
-110 0 0 -595z"/>
<path d="M2550 2555 l0 -595 160 0 160 0 0 595 0 595 -160 0 -160 0 0 -595z"/>
<path d="M2800 1835 c0 -32 -2 -35 -30 -35 -43 0 -70 -31 -70 -80 0 -61 25
-80 100 -78 l60 2 0 113 0 113 -30 0 c-28 0 -30 -3 -30 -35z m0 -116 c0 -33
-3 -40 -17 -37 -32 6 -30 78 2 78 11 0 15 -11 15 -41z"/>
<path d="M3650 1755 l0 -115 30 0 30 0 0 115 0 115 -30 0 -30 0 0 -115z"/>
<path d="M2497 1833 c-4 -3 -7 -15 -7 -25 0 -10 -4 -18 -10 -18 -5 0 -10 -9
-10 -20 0 -11 4 -20 9 -20 4 0 11 -19 14 -42 3 -23 14 -48 23 -55 20 -15 80
-17 88 -4 10 16 -5 40 -27 43 -16 2 -23 11 -25 31 -3 23 0 27 22 27 34 0 37
37 4 42 -15 2 -24 11 -26 26 -2 15 -10 22 -26 22 -12 0 -26 -3 -29 -7z"/>
<path d="M3080 1815 c0 -16 -6 -25 -15 -25 -8 0 -15 -9 -15 -20 0 -11 7 -20
15 -20 11 0 15 -11 15 -39 0 -47 26 -71 78 -71 29 0 33 3 30 23 -2 14 -10 22
-23 22 -15 0 -21 8 -23 33 -3 27 0 32 17 32 14 0 21 6 21 20 0 13 -7 20 -20
20 -15 0 -20 7 -20 25 0 21 -5 25 -30 25 -25 0 -30 -4 -30 -25z"/>
<path d="M3255 1800 c-27 -5 -31 -10 -26 -40 0 -6 12 -8 25 -4 13 3 27 1 30
-5 4 -6 -1 -11 -11 -11 -27 0 -63 -28 -63 -50 0 -34 26 -49 85 -47 l55 1 0 61
c0 85 -24 109 -95 95z m35 -105 c0 -8 -7 -15 -15 -15 -16 0 -20 12 -8 23 11
12 23 8 23 -8z"/>
<path d="M894 1794 c-8 -3 -2 -29 17 -77 l28 -72 -20 -17 c-24 -20 -18 -63 8
-54 8 3 19 6 24 6 14 0 37 41 73 133 l34 87 -29 0 c-24 0 -30 -5 -40 -40 -7
-22 -15 -40 -19 -40 -4 0 -12 18 -19 40 -11 39 -25 47 -57 34z"/>
<path d="M1091 1774 c-47 -60 -8 -134 69 -134 80 0 119 82 65 135 -35 36 -105
35 -134 -1z m89 -29 c19 -23 8 -50 -20 -50 -16 0 -26 6 -28 18 -4 18 14 47 28
47 4 0 13 -7 20 -15z"/>
<path d="M1270 1746 c0 -83 12 -106 55 -106 20 0 45 5 55 10 13 7 21 7 25 0 3
-5 13 -10 21 -10 11 0 14 17 14 80 l0 80 -30 0 c-28 0 -29 -1 -32 -57 -3 -50
-6 -58 -23 -58 -17 0 -20 8 -23 58 -3 56 -4 57 -32 57 -30 0 -30 -1 -30 -54z"/>
<path d="M1478 1793 c-3 -5 -5 -41 -6 -80 l-3 -73 30 0 c29 0 30 2 33 48 2 38
7 49 26 57 22 10 30 39 12 50 -5 3 -20 1 -32 -6 -16 -9 -23 -9 -26 0 -4 12
-29 15 -34 4z"/>
<path d="M1686 1784 c-9 -8 -16 -22 -16 -29 0 -17 39 -55 57 -55 7 0 13 -5 13
-11 0 -6 -11 -8 -29 -4 -22 5 -30 2 -35 -11 -8 -22 10 -34 52 -34 78 0 100 54
37 91 -41 25 -45 33 -10 27 26 -6 51 14 40 32 -10 16 -91 12 -109 -6z"/>
<path d="M1830 1731 c0 -44 5 -72 13 -79 7 -6 43 -10 80 -9 l67 2 0 77 c0 77
0 78 -25 78 -23 0 -25 -4 -26 -42 -1 -24 -1 -48 0 -55 2 -18 -40 -28 -50 -12
-5 8 -9 35 -9 62 0 43 -2 47 -25 47 -24 0 -25 -2 -25 -69z"/>
<path d="M2030 1720 l0 -80 30 0 c29 0 30 1 30 49 0 41 4 51 20 56 21 7 27 39
10 50 -5 3 -18 1 -29 -5 -13 -7 -22 -7 -26 0 -3 5 -13 10 -21 10 -11 0 -14
-17 -14 -80z"/>
<path d="M2170 1778 c-30 -33 -27 -91 6 -117 27 -21 96 -29 121 -13 21 13 -4
37 -38 37 -18 0 -34 5 -36 12 -3 8 10 11 39 10 36 -1 43 2 46 19 2 11 -7 32
-18 47 -28 35 -89 38 -120 5z m78 -36 c2 -7 -6 -12 -17 -12 -21 0 -27 11 -14
24 9 9 26 2 31 -12z"/>
<path d="M2340 1786 c-20 -25 -8 -60 27 -76 41 -19 42 -34 3 -26 -23 4 -32 2
-37 -10 -8 -21 14 -34 59 -34 71 0 92 57 33 91 -41 25 -45 33 -10 26 25 -4 53
22 38 37 -13 12 -101 6 -113 -8z"/>
<path d="M2906 1791 c-12 -19 6 -41 29 -35 13 3 26 1 29 -5 4 -6 -1 -11 -11
-11 -27 0 -63 -28 -63 -50 0 -34 26 -49 85 -47 l55 1 0 59 c0 83 -10 97 -70
97 -27 0 -51 -4 -54 -9z m64 -96 c0 -8 -7 -15 -15 -15 -16 0 -20 12 -8 23 11
12 23 8 23 -8z"/>
<path d="M3460 1683 l0 -113 30 0 c28 0 30 3 30 35 0 32 2 35 30 35 60 0 93
83 54 138 -13 19 -23 22 -80 20 l-64 -2 0 -113z m95 37 c0 -25 -5 -36 -17 -38
-15 -3 -18 4 -18 38 0 34 3 41 18 38 12 -2 17 -13 17 -38z"/>
<path d="M3750 1731 c0 -44 5 -72 13 -79 7 -6 43 -10 80 -9 l67 2 0 77 c0 77
0 78 -25 78 -23 0 -25 -4 -26 -42 -1 -24 -1 -48 0 -55 2 -18 -40 -28 -50 -12
-5 8 -9 35 -9 62 0 43 -2 47 -25 47 -24 0 -25 -2 -25 -69z"/>
<path d="M3966 1784 c-33 -32 -33 -96 0 -128 8 -9 31 -16 51 -16 31 0 34 -2
23 -15 -8 -10 -26 -15 -49 -13 -30 3 -36 0 -36 -17 0 -17 8 -20 54 -23 82 -5
91 8 91 128 l0 97 -59 2 c-42 1 -64 -4 -75 -15z m82 -58 c4 -36 -12 -54 -33
-36 -18 15 -20 41 -3 58 18 18 33 9 36 -22z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
